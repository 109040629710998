<template>
   <v-dialog :value="!!uid" persistent max-width="600" >
      <v-card :loading="loading">
         <v-card-title>
            User Profile
         </v-card-title>
         <v-card-text>
            <v-form ref="form">
               <v-text-field label="Name" v-model="usr.name" @change="$set(update,'name', arguments[0])" :rules="[v => !!v || 'Nama lengkap harus diisi']"/>
               <v-text-field readonly label="Email" v-model="usr.email" />
               <v-text-field v-model="usr.active_admin_status_name" readonly label="Status" />
            </v-form>
         </v-card-text>
         <v-card-actions>
            <v-spacer/>
            <v-btn width="120" outlined rounded color="secondary" @click="$emit('close')">tutup</v-btn>
            <v-btn width="180" :disabled="no_update" :loading="load_save" outlined rounded color="primary" @click="save">simpan</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>
<script>
import config from '../config';
import api from '../api';

export default {
   props: ['uid'],
   data: () => ({
      usr: {},
      pwd: {},
      ch_passwd: false,
      files: [],
      update: {},
      loading: false,
      load_save: false,
   }),
   computed: {
      avatar() {
         return this.usr.avatar && config.img_url + this.usr.avatar;
      },
      no_update() {
         console.log(Object.keys(this.update));
         return Object.keys(this.update).length === 0 && this.uid !== 'new';
      }
   },
   mounted () {
   },
   watch: {
      uid(val) {
         if(val) {
            if(val === 'new') {
               this.usr = {id: 'new'};
            } else {
               this.loading = true;
               api.get(val==='profile'?'profile':`user/${val}`).then((rsl) => {
                  if(rsl.data) {
                     this.usr = rsl.data;
                  }
               }).finally(() => this.loading = false)
            }
         } else this.$refs.form.reset();
      }
   },
   methods: {
      checkPasswd(v) {
         return v === this.usr.password || 'Password tidak sama';
      },
      close(reload) {
         this.$emit('close', reload);
      },
      async save() {
         if(!this.$refs.form.validate()) return;
         let rsl;
         this.load_save = true;
         const {name} = this.update;
         if(name) {
            rsl = await api.edit(`profile`,this.usr.id, {name});
         }
         this.load_save = false;
         if(rsl) {
            if(rsl.error) return this.$alert('Ooops!', rsl.msg, 'error');
            this.$alert({title: 'Berhasil!', text: rsl.success, type: 'success', timer: 2000 });
            this.close(true);
         }
      },
   }
}
</script>
