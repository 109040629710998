<template>
   <v-dialog :value="$store.state.alert.length" persistent @click:outside="close" max-width="400">
      <v-card v-for="(a,i) in $store.state.alert" :key="i">
         <v-icon v-if="a.type==='error'" color="red">mdi-alert</v-icon>
         <v-icon v-if="a.type==='question'" color="green">mdi-help-circle-outline</v-icon>
         <v-icon v-if="a.type==='warning'" color="#fc0">mdi-alert</v-icon>
         <v-icon v-if="a.type==='info'" color="info">mdi-information-outline</v-icon>
         <v-icon v-if="a.type==='success'" color="primary">mdi-checkbox-marked-circle-outline</v-icon>
         <v-card-title v-if="a.title">{{a.title}}</v-card-title>
         <v-card-text :class="a.title?'':'title pt-5'" class="pr-16">{{a.text}}</v-card-text>
         <v-card-actions v-if="!a.nobtn">
            <v-spacer />
            <v-btn v-if="a.cancel" width="100" outlined rounded color="secondary" @click="cancel(i, a.cancel)">batal</v-btn>
            <v-btn width="100" outlined rounded color="primary" @click="ok(i, a.ok)">ok</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>
<script>
export default {
   methods: {
      close() {
         if(this.$store.state.alert.length === 1) {
            this.$store.commit('delAlert', 0);
         }
      },
      cancel(i, cb) {
         this.$store.commit('delAlert', i);
         if(typeof cb === 'function') cb();
      },
      ok(i, cb) {
         this.$store.commit('delAlert', i);
         if(typeof cb === 'function') cb();
      }
   }
}
</script>
<style scoped>
i.v-icon {
   position: absolute;
   right: 12px;
   top: 12px;
   font-size: 60px;
}
</style>
