import axios from 'axios';
import CFG from './config';
import cookies from 'js-cookie';
import router from './router'

// const token = cookies.get(CFG.tokenKey)

axios.defaults.baseURL = CFG.api_url;
axios.interceptors.response.use(null, function (error) {
   if (error.response.status === 401) {
     console.log('got 401 redirect to login')
     router.push('/Login');
   }
   return Promise.reject(error)
});

export default {
   login: (data) => axios.post('/login', data).then(({data}) => {
      if(data.data && data.data.auth) {
         cookies.set(CFG.tokenKey, data.data.auth.token, {expires: 1});
         cookies.set(CFG.tokenKey +'2', data.data.auth.refreshToken, {expires: 1});
         axios.defaults.headers.common[CFG.tokenHeaderKey] = 'bearer '+ data.data.auth.token;
      }
      return data;
   }),
   create: (mod, data) => axios.post(mod, data).then(rsl => rsl.data),
   get: (mod, filter) => axios.get(mod, {params: filter}).then(rsl => rsl.data),
   detail: (mod, id) => axios.get(mod +'/'+ id).then(rsl => rsl.data),
   edit: (mod, id, data) => axios.put(mod +'/'+ id, data).then(rsl => rsl.data),
   enable: (mod, id, data) => axios.put(`${mod}/${id}/activate`, data).then(rsl => rsl.data),
   disable: (mod, id, data) => axios.put(`${mod}/${id}/inactivate`, data).then(rsl => rsl.data),
   del: (mod, id, data) => axios.delete(id?`${mod}/${id}`:mod, {data: data}).then(rsl => rsl.data),
}
