import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import alert from './plugins/nalert'
import myDirective from './plugins/my-directive'

Vue.config.productionTip = false
Vue.use(alert);
Vue.use(myDirective);

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
