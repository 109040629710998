import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/components/layout.vue'

Vue.use(VueRouter)

const routes = [
   { path: '/login', component: () => import('@/views/Login') },
   { path: '/reset-password', name: 'Reset Password', component: () => import(/* webpackChunkName: "rpassword" */ '../views/ResetPassword') },
   { path: '/forgot-password', name: 'Forgot Password', component: () => import(/* webpackChunkName: "fpassword" */ '../views/ForgotPassword') },
   { 
      path: '/', name: 'Home', component: Layout, redirect: '/dashboard', children: [
         { path: '/dashboard', name: 'Dashboard', component: () => import(/* webpackChunkName: "home" */ '../views/Dashboard') },
         { path: '/category', name: 'Categorys', component: () => import(/* webpackChunkName: "category" */ '../views/category') },
         { path: '/customer', name: 'Pelanggan', component: () => import(/* webpackChunkName: "customer" */ '../views/Customer') },
         { path: '/product', name: 'Produk', component: () => import(/* webpackChunkName: "product" */ '../views/product') },
         { path: '/transaction', name: 'Transaksi', component: () => import(/* webpackChunkName: "transaction" */ '../views/Transaction') },
         { path: '/user', name: 'Users', component: () => import(/* webpackChunkName: "user" */ '../views/User') },
      ]
   },
]

const router = new VueRouter({
   mode: 'history',
   base: process.env.BASE_URL || '/salestool',
   routes
})

export default router
