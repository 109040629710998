<template>
   <v-app>
      <drawer />
      <toolbar />
      <v-main>
         <v-fade-transition mode="out-in">
            <router-view />
         </v-fade-transition>
      </v-main>
      <alert />
   </v-app>
</template>

<script>
import Drawer from './Navigation';
import cookies from 'js-cookie'
import CFG from '@/config'
import api from '../api';
import Alert from './Alert.vue';
import Toolbar from './Toolbar.vue';

export default {
   name: 'Main',
   components: { Drawer, Alert, Toolbar, },
   data: () => ({
   }),
   beforeRouteEnter(to, from, next) {
      const token = cookies.get(CFG.tokenKey);
      if(!token) next('/login');
      else next();
   },
   beforeCreate() {
      const {user} = this.$store.state.auth;
      if(!user.id) {
         api.get('profile').then(rsl => {
            this.$store.commit('auth/set', rsl.data);
         })
      }
   },
   mounted() {
   },
};
</script>
<style>
.list-line {display: flex;justify-content: space-between;align-items: center;min-height: 36px;border-bottom: 1px solid #ddd;}
</style>
