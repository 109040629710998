import cookies from 'js-cookie'
import CFG from '@/config'
import axios from 'axios'

let token = cookies.get(CFG.tokenKey);
if(token) {
   axios.defaults.headers.common[CFG.tokenHeaderKey] = 'bearer '+ token;
}

export default {
   namespaced: true,
   state: {user: {}, token: token, loggingIn: !!token},
   actions: {
      ok({commit}, {token, data}) {
         cookies.set(CFG.tokenKey, token, {expires: 1});
         axios.defaults.headers.common[CFG.tokenHeaderKey] = `Bearer ${token}`;
         commit('set', data);
      },
      logout({ commit }) {
         delete axios.defaults.headers.common[CFG.tokenHeaderKey]
         cookies.remove(CFG.tokenKey)
         commit('clear');
      }
   },
   mutations: {
      set(state, {avatar,...user}) {
         state.loggingIn = true;
         if(avatar) user.avatar = CFG.img_url + avatar;
         else user.avatar = '~/assets/avatar.png';
         state.user = user;
      },
      loginFailure(state) {
         state.loggingIn = false;
         state.user = {};
      },
      clear(state) {
         state.loggingIn = false;
         state.user = {};
      },
      setToken() {
         
      }
   }
}
