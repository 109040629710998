import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer: true,
    alert: [],
  },
  mutations: {
  set(state, arr) {
    for(let i in arr) state[i] = arr[i];
  },
  toggleDrawer(state) {
    state.drawer = !state.drawer;
  },
  addAlert(state, data) {
    state.alert.push(data);
  },
  delAlert(state, idx) {
    state.alert.splice(idx, 1);
  },
},
  actions: {
  },
  modules: {
    auth
  }
})
