export default {
   api_url: process.env.VUE_APP_API_URL || '/api/adcp',
   img_url: process.env.VUE_APP_IMG_URL || 'http://storage.mediacartz.com',
   tokenKey: 'st-token',
   tokenHeaderKey: 'Authorization',
   base_url: '/salestool',
   avatar_width: 150,
   avatar_height: 150,
   site_name: 'Sales Tools',
   site_caption: "PT Exel Integrasi Solusindo<br/>Indonesia",
}
